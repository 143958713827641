import {
  doc,
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "./firebase";
import { v4 as uuidv4 } from "uuid";
import md5 from "md5";
import { getDate } from "pages/orders";

function generateNumericUUID(): number {
  let id = "";

  // There may be some edge cases where the hash does not have 13 digits
  // as we would like.
  // So loop until we build a string that has 13 digits,
  // generating a new hash each time.
  while (id.length !== 13) {
    let uuid = uuidv4();
    let hash = md5(uuid);
    for (const chr of hash) {
      if (!isNaN(parseInt(chr))) {
        if (id.length === 13) {
          break;
        }
        id = id + chr;
      }
    }
  }

  return parseInt(id.substring(0, 13));
}

export const dataProvider: any = {
  create: async ({ resource, variables }: any) => {
    let params = variables;
    if (resource === "stores") {
      try {
        let user: any = await createUserWithEmailAndPassword(
          auth,
          variables.email,
          variables.password
        );
        if (user) {
          params = { ...params, uid: user.user.uid, type: "vendor" };
        }
      } catch (error: any) {
        throw new Error("Email already exists");
      }
    }
    if (resource === "orders") {
      params.orderNumber = generateNumericUUID();
      params.orderStatusArray = params.orderStatus
        ? [{ children: params.orderStatus, label: String(new Date()) }]
        : [{ children: "Order Received", label: String(new Date()) }];
      params.user = params.user ? params.user : "";
      params.employeeID = params.employeeID ? params.employeeID : "";
      params.orderStatus = params.orderStatus
        ? params.orderStatus
        : "Order Received";
      params.logisticCompanyProvider = params.logisticCompanyProvider
        ? params.logisticCompanyProvider
        : "";
      params.logisticConfirmationNumber = params.logisticConfirmationNumber
        ? params.logisticConfirmationNumber
        : "";
      params.paymentConfirmation = params.paymentConfirmation || "";
    }
    params["createAt"] = String(new Date());
    params["updateAt"] = String(new Date());
    const dbRef = collection(db, resource);
    addDoc(dbRef, params)
      .then(() => {
        return Promise.resolve();
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  createMany: ({ resource, variables, metaData }: any) => {
    return Promise.resolve();
    // return Promise.reject();
  },
  deleteOne: async ({ resource, id, variables, metaData }: any) => {
    try {
      await deleteDoc(doc(db, resource, id));
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteMany: ({ resource, ids, variables, metaData }: any) => {
    return Promise.resolve();
    // return Promise.reject();
  },
  getList: async ({
    resource,
    pagination,
    hasPagination,
    sort,
    filters,
    metaData,
  }: any) => {
    try {
      const queryConstraints = [];
      if (filters) {
        for (let item of filters) {
          if (item.operator == "boolean") {
            queryConstraints.push(
              where(item.field, "==", item.value == "true" ? true : false)
            );
          } else if (item.operator == "id" || item.operator == "in") {
          } else {
            if (item.value && Array.isArray(item.value)) {
              for (let val of item.value) {
                queryConstraints.push(where(item.field, "==", val));
              }
            } else if (item.value) {
              queryConstraints.push(where(item.field, "==", item.value));
            }
          }
        }
      }
      if (sort) {
        for (let item of sort) {
          queryConstraints.push(orderBy(item.field, item.order));
        }
      }
      let q = query(collection(db, resource), ...queryConstraints);
      const querySnapshot = await getDocs(q);
      let collections: any = [];
      querySnapshot.forEach((doc: any) => {
        collections.push({ ...doc.data(), id: doc.id });
      });
      collections.sort((a: any, b: any) => {
        return new Date(b.updateAt).getTime() - new Date(a.updateAt).getTime();
      });
      if (resource == "orders") {
        let filterId = filters.find((item: any) => item.operator == "id");
        if (filterId && filterId.value) {
          let collection = collections.filter(
            (item: any) => item.id == filterId.value
          );
          collections = collection ? collection : [];
        }
        let filterIn = filters.find((item: any) => item.operator == "in");
        if (filterIn && filterIn.value) {
          let collection = collections.filter((element: any) =>
            filterIn.value.includes(element.orderStatus)
          );
          collections = collection ? collection : [];
        }
        // Try sorting order array by date, until this if-statement ends.
        collections = collections.map((order: any) => {
          const date = getDate(order);
          if (date) {
            return { ...order, date };
          } else {
            return order;
          }
        });
        collections = collections.sort((a: any, b: any) => {
          if (a.date && b.date) {
            return b.date.getTime() - a.date.getTime();
          } else if (a.date) {
            return 1;
          } else if (b.date) {
            return -1;
          } else {
            return 0;
          }
        });
        const ordersWithDate = collections.filter((order: any) => order.date);
        const ordersWithoutDate = collections.filter(
          (order: any) => !order.date
        );
        collections = [...ordersWithDate, ...ordersWithoutDate];
      }
      if (resource == "stores") {
        let filterIn = filters.find((item: any) => item.operator == "in");
        if (filterIn && filterIn.value) {
          let collection = collections.filter((element: any) =>
            filterIn.value.includes(element.id)
          );
          collections = collection ? collection : [];
        }
      }
      if (resource == "shopify-stores") {
        collections = collections.map((c: any) => ({
          ...c,
          created: c.created ?? 0
        }));
        collections = collections.sort((a: any, b: any) => b.created - a.created);
      }
      if (resource == "shopify-orders") {
        collections = collections.map((c: any) => ({
          ...c,
          created: new Date(c.orderStatusArray[0]?.label ?? new Date())
        }));
        collections = collections.sort((a: any, b: any) => b.created - a.created);
      }
      return Promise.resolve({ data: collections });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getMany: ({ resource, ids, metaData }: any) => {
    return Promise.resolve();
    // return Promise.reject();
  },
  getOne: async ({ resource, id, metaData }: any) => {
    try {
      const docRef = doc(db, resource, id);
      const docSnap = await getDoc(docRef);
      return Promise.resolve({ data: { ...docSnap.data(), id } });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  update: ({ resource, id, variables, metaData }: any) => {
    if (resource === "orders" && variables.orderStatusArray) {
      let isExit = variables.orderStatusArray.find(
        (item: any) => item.children == variables.orderStatus
      );
      if (isExit == undefined) {
        variables.orderStatusArray = [
          ...variables.orderStatusArray,
          { children: variables.orderStatus, label: String(new Date()) },
        ];
      }
    }
    if (resource === "orders") {
      variables.employeeID = variables.employeeID ? variables.employeeID : "";
      variables.logisticCompanyProvider = variables.logisticCompanyProvider
        ? variables.logisticCompanyProvider
        : "";
      variables.logisticConfirmationNumber =
        variables.logisticConfirmationNumber
          ? variables.logisticConfirmationNumber
          : "";
    }
    variables["updateAt"] = String(new Date());
    let finalData: any = {};
    // Filter out undefined variables as saving undefined to Firebase gives an error.
    for (const [key, value] of Object.entries(variables)) {
      if (value !== undefined) {
        finalData[key] = value;
      }
    }
    const docRef = doc(db, resource, id);
    updateDoc(docRef, finalData)
      .then((docRef) => {
        return Promise.resolve();
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  updateMany: ({ resource, ids, variables, metaData }: any) => {
    return Promise.resolve();
    // return Promise.reject();
  },
  custom: ({
    url,
    method,
    sort,
    filters,
    payload,
    query,
    headers,
    metaData,
  }: any) => Promise,
  getApiUrl: () => "",
};
