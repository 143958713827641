import { Logo } from "./styled";
import { Menu } from "antd";
import * as Icons from "@ant-design/icons";
import { useState } from "react";

type TitleProps = {
  collapsed: boolean;
};

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
  // State, used to refresh keys
  const [rand, setRand] = useState(Math.random());

  const links = [
    { name: "Store Dashboard", url: "https://store.wecarrybags.co.uk", icon: <Icons.ShopOutlined /> },
    { name: "PSS Dashboard", url: "https://pss.wecarrybags.co.uk", icon: <Icons.ShoppingOutlined /> },
    { name: "Website", url: "https://wecarrybags.co.uk", icon: <Icons.HomeOutlined /> },
    { name: "Web App", url: "https://app.wecarrybags.co.uk", icon: <Icons.AppstoreOutlined /> },
  ]

  return (
    <>
      <Logo>
        {collapsed ? (
          <img style={{ width: 25 }} src="/images/logo.png" alt="CBVENDON" />
        ) : (
          <img style={{ width: 50 }} src="/images/logo.png" alt="CBVENDON" />
        )}
      </Logo>
      <Menu theme="dark" style={{ marginBottom: 5 }} items={links.map(l => ({
        key: Math.random(), // Random key to prevent highlight on click
        label: l.name,
        icon: l.icon,
        onClick: () => {
          setRand(Math.random());
          window.open(l.url, "_blank");
        },
        style: { padding: '0 24px' }
      }))} />
    </>
  );
};
