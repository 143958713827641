import {
  useTranslate,
  IResourceComponentsProps,
  CrudFilters,
  useExport,
  useNavigation,
  HttpError,
  getDefaultFilter,
  useUpdate,
} from "@refinedev/core";

import {
  List,
  useTable,
  DateField,
  useSelect,
  CreateButton,
} from "@refinedev/antd";
import {
  Typography,
  Table,
  Popover,
  Space,
  Card,
  Form,
  Select,
  Button,
  FormProps,
  Row,
  Col,
} from "antd";
import RefineReactRouter from "@refinedev/react-router-v6/legacy";
import dayjs from "dayjs";

import { OrderStatus, OrderActions, OrderStatusProps } from "components";
import { IOrder, IStore, IOrderFilterVariables } from "interfaces";
import { useMemo } from "react";
import { isNaN } from "lodash";

const tryGetDate = (dateString: any) => {
  const date = new Date(dateString);
  // Returns NaN if date is invalid,
  // and therefore lets us distinguish between a valid and an invalid date.
  const time = date.getTime();
  if (isNaN(time)) {
    return null;
  } else {
    return date;
  }
};

export const getDateWithoutArray = (label: string) => {
  const dateParts = label.split("/");
  const firstPart = dateParts[1];
  const secPart = dateParts[0];
  const thirdPart = dateParts[2];
  const newStr = `${firstPart}/${secPart}/${thirdPart}`;
  return new Date(newStr);
};

export const getDate = (record: any) => {
  if (
    record.orderStatusArray &&
    record.orderStatusArray.length > 0 &&
    record.orderStatusArray[0].label
  ) {
    const firstDate = record.orderStatusArray[0].label;
    // Have to perform some processing, because date is stored in dd/mm/yy format
    // but Javascript expects string in mm/dd/yy format.
    const dateParts = firstDate.split("/");
    const firstPart = dateParts[1];
    const secPart = dateParts[0];
    const thirdPart = dateParts[2];
    const newStr = `${firstPart}/${secPart}/${thirdPart}`;
    const newDate = tryGetDate(newStr);
    if (newDate) {
      return newDate;
    } else {
      return tryGetDate(firstDate);
    }
  } else if (record.createDate) {
    return tryGetDate(record.createDate);
  } else {
    return null;
  }
};

export const OrderList: React.FC<IResourceComponentsProps> = () => {
  const {
    tableProps,
    sorters: sorter,
    searchFormProps,
    filters,
  } = useTable<IOrder, HttpError, IOrderFilterVariables>({
    resource: "orders",

    onSearch: (params) => {
      const filters: any = [];
      const { orderStatus, isPaid, order, store, user } = params;
      filters.push({
        field: "orderStatus",
        operator: "in",
        value: orderStatus
          ? orderStatus == "Not Delivered"
            ? ["Out for Delivery", "Order Packaged", "Order Received"]
            : [orderStatus]
          : "",
      });
      filters.push({
        field: "isPaid",
        operator: "boolean",
        value: isPaid,
      });

      filters.push({
        field: "orderNumber",
        operator: "eq",
        value: order,
      });

      filters.push({
        field: "store",
        operator: "eq",
        value: store,
      });

      filters.push({
        field: "user",
        operator: "eq",
        value: user,
      });

      return filters;
    },
  });

  const { mutate } = useUpdate<IOrder>();

  const t = useTranslate();
  const { show } = useNavigation();
  const { Link } = RefineReactRouter;
  useExport<IOrder>({
    filters,
    pageSize: 50,
    maxItemCount: 50,

    mapData: (item: any) => {
      return {
        id: item.id,
        paymentConfirmation: item.paymentConfirmation,
        serviceType: item.serviceType,
        products: item.products,
        servicePrice: item.servicePrice,
        employeeID: item.employeeID,
        store: item.store,
        bags: item.bags,
        logisticCompanyProvider: item.logisticCompanyProvider,
        isPaid: item.isPaid,
        logisticConfirmationNumber: item.logisticConfirmationNumber,
        orderStatus: item.orderStatus,
        orderNumber: item.orderNumber,
        date: getDate(item),
      };
    },

    sorters: sorter,
  });

  const Actions: React.FC = () => (
    <Space>
      <CreateButton />
      {/* <ExportButton onClick={triggerExport} loading={isLoading} /> */}
    </Space>
  );

  return (
    <Row gutter={[16, 16]}>
      <Col
        xl={6}
        lg={24}
        xs={24}
        style={{
          marginTop: "6px",
        }}
      >
        <Typography.Title  level={4} style={{ marginBottom: "14px" }}>
          {new Date().toDateString()}
        </Typography.Title>
        <Card title={t("orders.filter.title")}>
          <Filter formProps={searchFormProps} filters={filters || []} />
        </Card>
      </Col>
      <Col xl={18} xs={24}>
        <List
          headerProps={{
            extra: <Actions />,
          }}
        >
          <Table {...tableProps} rowKey="id">
            {/* <Table.Column dataIndex="id" title="ID" align="center" /> */}
            <Table.Column
              width={1}
              key="id"
              dataIndex="id"
              title={t("orders.fields.orderNumber")}
              render={(value, record: any) => (
                <Popover title={record.orderNumber} trigger="hover">
                  <Button style={{ padding: 0 }} onClick={() => show("orders", value)} type="link">
                    Order ID
                  </Button>
                </Popover>
              )}
            />
            <Table.Column<IOrder>
              width={1}
              key="orderStatus"
              dataIndex={"orderStatus"}
              title={t("orders.fields.status")}
              render={(value, record) => {
                const options: OrderStatusProps["status"][] = [
                  "Delivered",
                  "Out for Delivery",
                  "Order Packaged",
                  "Order Received",
                  "Cancelled",
                ];

                return (
                  <Select
                    options={options.map(option => ({
                      label: <OrderStatus status={option} />,
                      value: option,
                    }))}
                    onSelect={value => {
                      mutate({
                        mutationMode: "optimistic",
                        invalidates: [],
                        resource: "orders",
                        id: record.id,
                        values: { orderStatus: value }
                      });
                    }}
                    value={value}
                    style={{ width: 155 }}
                    showSearch
                  />
                );
              }}
              // sorter
            />
            <Table.Column
              width={1}
              align="right"
              key="servicePrice"
              dataIndex="total"
              title={"Service Price"}
              render={(value, record: any) => {
                return value || value === 0
                  ? `${record.currencySymbol}${value}`
                  : "";
              }}
            />
            <Table.Column
              align="right"
              key="serviceType"
              dataIndex="serviceType"
              title={"Service Type"}
            />
            <Table.Column
              key="store.id"
              dataIndex={"store"}
              title={t("orders.fields.store")}
              render={(value) => (
                <Popover title={value} trigger="hover">
                  <Link to={`/stores/edit/${value}`} type="link">
                    Store ID
                  </Link>
                </Popover>
              )}
            />
            <Table.Column
              key="user.fullName"
              dataIndex={"user"}
              title={t("orders.fields.user")}
              render={(value) => (
                <Popover title={value} trigger="hover">
                  <Button style={{ padding: 0 }} type="link">User ID</Button>
                </Popover>
              )}
            />
            {/* <Table.Column<IOrder>
              key="products"
              dataIndex="products"
              title={t("orders.fields.products")}
              render={(_, record) => (
                <Popover title="Products" trigger="hover">
                  {t("orders.fields.itemsAmount", {
                    amount: record?.products?.length,
                  })}
                </Popover>
              )}
            /> */}
            <Table.Column
              key="createDate"
              dataIndex="createDate"
              title={"Order Date"}
              render={(value, record: any) => {
                const date = getDate(record);
                if (date) {
                  return <DateField value={date} format="DD/MM/YYYY" />;
                } else if (record.createDate) {
                  return <DateField value={value} format="DD/MM/YYYY" />;
                } else {
                  return <>No date</>;
                }
              }}
              // sorter
            />
            <Table.Column<IOrder>
              fixed="right"
              title={t("table.actions")}
              dataIndex="actions"
              key="actions"
              align="center"
              render={(_value, record) => <OrderActions record={record} />}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};

const Filter: React.FC<{ formProps: FormProps; filters: CrudFilters }> = (
  props
) => {
  const t = useTranslate();
  const { formProps, filters } = props;
  const { selectProps: orderSelectProps } = useSelect<IStore>({
    resource: "orders",
    optionLabel: "orderNumber" as any,
    optionValue: "orderNumber" as any,
    defaultValue: getDefaultFilter("order.orderNumber", filters),

    pagination: {
      mode: "server",
    },
  });
  const { selectProps: storeSelectProps } = useSelect<IStore>({
    resource: "stores",
    optionLabel: "id",
    optionValue: "id",
    defaultValue: getDefaultFilter("store.id", filters),

    pagination: {
      mode: "server",
    },
  });

  const { selectProps: userSelectProps } = useSelect<IStore>({
    resource: "users",
    optionLabel: "id",
    optionValue: "id",
    defaultValue: getDefaultFilter("user.id", filters),

    pagination: {
      mode: "server",
    },
  });

  const createdAt = useMemo(() => {
    const start = getDefaultFilter("createdAt", filters, "gte");
    const end = getDefaultFilter("createdAt", filters, "lte");
    const startFrom = dayjs(start);
    const endAt = dayjs(end);
    if (start && end) {
      return [startFrom, endAt];
    }
    return undefined;
  }, [filters]);

  return (
    <Form
      layout="vertical"
      {...formProps}
      initialValues={{
        store: getDefaultFilter("store.id", filters),
        user: getDefaultFilter("user.id", filters),
        status: getDefaultFilter("status.text", filters, "in"),
        createdAt,
      }}
    >
      <Row gutter={[10, 0]} align="bottom">
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={"Order Status"} name="orderStatus">
            <Select
              options={[
                { label: "Delivered", value: "Delivered" },
                { label: "Not Delivered", value: "Not Delivered" },
                { label: "Out for Delivery", value: "Out for Delivery" },
                { label: "Order Packaged", value: "Order Packaged" },
                { label: "Order Received", value: "Order Received" },
              ]}
              allowClear
              placeholder={t("orders.filter.status.placeholder")}
              showSearch
            ></Select>
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={"Paid"} name="isPaid">
            <Select
              options={[
                { label: "Done", value: "true" },
                { label: "Not Done", value: "false" },
              ]}
              allowClear
              placeholder={"Payment Status"}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            ></Select>
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={"Order"} name="order">
            <Select
              // {...orderSelectProps}
              options={orderSelectProps.options}
              allowClear
              placeholder={"Search Orders"}
              showSearch
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={t("orders.filter.store.label")} name="store">
            <Select
              // {...storeSelectProps}
              options={storeSelectProps.options}
              allowClear
              placeholder={t("orders.filter.store.placeholder")}
              showSearch
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={t("orders.filter.user.label")} name="user">
            <Select
              // {...userSelectProps}
              options={userSelectProps.options}
              allowClear
              placeholder={t("orders.filter.user.placeholder")}
              showSearch
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item>
            <Button htmlType="submit" type="primary" size="large" block>
              {t("orders.filter.submit")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
